import { makeStyles, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React, { FC } from 'react';
import { AdminButtonGroup } from '../../general/units/adminButtonGroup';
import { AdminInput } from '../../settings/parts/adminInput';
import { SettingsDialog } from '../../settings/parts/settingsDialog';

type Props = {
  open: boolean;
  positiveText: string;
  userName: { value: string; errMsg: string };
  userMail: { value: string; errMsg: string };
  onChange: { name: (value: string) => void; mail: (value: string) => void };
  onBlur: { name: (value: string) => void; mail: (value: string) => void };
  onClose(): void;
  onPositiveClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '1rem',
    width: '90%',
    textAlign: 'left',
    color: '#5b5b5b',
  },
  iconButton: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textFieldWrap: { width: '90%', marginBottom: '1rem' },
  textField: { width: '100%' },
  errMsgWrapper: { height: '0.5rem', marginTop: '0.5rem' },
  errMsg: { color: 'red', fontSize: '0.8rem' },
  input: { height: '1.5rem' },
  dialogButtonWrap: { width: '100%' },
}));

export const ForgetUserAuthDialog: FC<Props> = ({
  open,
  userName,
  userMail,
  positiveText,
  onChange,
  onBlur,
  onClose,
  onPositiveClick,
}) => {
  const classes = useStyles();

  return (
    <SettingsDialog open={open} onClose={onClose} height="45%">
      <DialogContent className={classes.dialogContent}>
        <div className={classes.title}>
          <Typography variant="h5">ユーザー認証</Typography>
        </div>
        <div className={classes.textFieldWrap}>
          <AdminInput
            className={classes.textField}
            inputClassName={classes.input}
            value={userName.value}
            placeholder="ユーザー名"
            onChange={onChange.name}
            onBlur={onBlur.name}
          />
          <div className={classes.errMsgWrapper}>
            <p className={classes.errMsg}>{userName.errMsg}</p>
          </div>
        </div>
        <div className={classes.textFieldWrap}>
          <AdminInput
            className={classes.textField}
            inputClassName={classes.input}
            value={userMail.value}
            placeholder="メールアドレス"
            onChange={onChange.mail}
            onBlur={onBlur.mail}
          />
          <div className={classes.errMsgWrapper}>
            <p className={classes.errMsg}>{userMail.errMsg}</p>
          </div>
        </div>
        <DialogActions className={classes.dialogButtonWrap}>
          <AdminButtonGroup
            positiveText={positiveText}
            negativeText="キャンセル"
            onPositiveClick={onPositiveClick}
            onNegativeClick={onClose}
          />
        </DialogActions>
      </DialogContent>
    </SettingsDialog>
  );
};
