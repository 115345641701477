import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { LoginLayout } from '../../components/login/layouts/loginLayout';
import { AuthContextProvider } from '../../modules/general/auth';
import { UIContextProvider } from '../../modules/general/ui';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

const LoginPage: FC = () => {
  const classes = useStyles();
  return (
    <UIContextProvider>
      <AuthContextProvider>
        <div className={classes.wrapper}>
          <LoginLayout />
        </div>
      </AuthContextProvider>
    </UIContextProvider>
  );
};

export default LoginPage;
