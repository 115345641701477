import { DialogContentText, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import { availableFunctions, FunctionInfo } from '../../../constants/takeout/functions';

import { TakeoutDialog } from '../../takeout/parts/takeoutDialog';

interface Props {
  isOpen: boolean;
}
const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: '30px 30px',
  },
  link: {
    marginTop: '20px',
    display: 'flex',
    textDecoration: 'none',
  },
  image: {
    height: '30px',
    width: '30px',
    marginRight: '3px',
  },
  title: {
    textAlign: 'center',
    width: '100%',
    color: 'white',
    backgroundColor: '#8ACC9C',
    padding: '4px 0',
  },
}));

export const SelectTransitionDialog: FC<Props> = ({ isOpen }) => {
  const classes = useStyles();
  return (
    <TakeoutDialog open={isOpen} height="auto" width="auto">
      <div className={classes.wrapper}>
        <DialogContentText>使用する機能をお選びください。</DialogContentText>
        {availableFunctions.map((func) => (
          <Link to={func.path} key={`select-transition-dialog-${func.path}`} className={classes.link}>
            <img className={classes.image} src={func.image} alt={func.title} />
            <Typography className={classes.title}>{func.title}</Typography>
          </Link>
        ))}
      </div>
    </TakeoutDialog>
  );
};
