import { ButtonBase, makeStyles } from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AuthContext, authOperations } from '../../../modules/general/auth';
import { UIContext } from '../../../modules/general/ui';
import { ForgetUserAuthDialog } from '../parts/forgetUserAuthDialog';

const useStyles = makeStyles((theme) => ({
  forgetMessageWrapper: {
    textAlign: 'center',
    marginTop: '0.5rem',
  },
  forgetMessage: {
    fontSize: '0.5rem',
    color: '#5b5b5b',
  },
}));

export const AuthForgetUserForm: FC = () => {
  const classes = useStyles();
  const authStore = useContext(AuthContext);
  const uiStore = useContext(UIContext);

  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [userNameErr, setUserNameErr] = useState({ flg: true, msg: '' });
  const [userNameBlurFlg, setUserNameBlurFlg] = useState(false);
  const [userMail, setUserMail] = useState('');
  const [userMailErr, setUserMailErr] = useState({ flg: true, msg: '' });
  const [userMailBlurFlg, setUserMailBlurFlg] = useState(false);

  const validateUserName = (value: string): string => {
    if (value.length === 0) {
      return 'ユーザー名を入力してください';
    }
    return '';
  };

  const validateUserMail = (value: string): string => {
    const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!REGEX_EMAIL.test(value)) {
      return 'メールアドレスを入力してください';
    }
    return '';
  };

  const handleErrorUserName = (value: string) => {
    const validateResult = validateUserName(value);
    setUserNameErr({ flg: !!validateResult, msg: validateResult });
  };

  const handleErrorUserMail = (value: string) => {
    const validateResult = validateUserMail(value);
    setUserMailErr({ flg: !!validateResult, msg: validateResult });
  };

  const onUserNameChange = (value: string) => {
    const trimValue = value.trim();

    if (trimValue.length <= 20) {
      setUserName(trimValue);
      if (userNameBlurFlg) {
        handleErrorUserName(trimValue);
      }
    }
  };

  const onUserMailChange = (value: string) => {
    const trimValue = value.trim();

    if (trimValue.length <= 256) {
      setUserMail(trimValue);
      if (userMailBlurFlg) {
        handleErrorUserMail(trimValue);
      }
    }
  };

  const onUserNameBlur = () => {
    setUserNameBlurFlg(true);
    handleErrorUserName(userName);
  };

  const onUserMailBlur = () => {
    setUserMailBlurFlg(true);
    handleErrorUserMail(userMail);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onAuthForgetUser = async () => {
    setUserNameBlurFlg(true);
    setUserMailBlurFlg(true);

    if (userNameErr.flg || userMailErr.flg) {
      handleErrorUserName(userName);
      handleErrorUserMail(userMail);
    } else {
      await authOperations.authForgetUser(uiStore.dispatch, authStore.dispatch, {
        name: userName,
        mail: userMail,
      });
    }
  };

  useEffect(() => {
    if (authStore.state.forgetUser) {
      onClose();
    }
  }, [authStore.state.forgetUser]);

  return (
    <>
      <div className={classes.forgetMessageWrapper}>
        <ButtonBase className={classes.forgetMessage} onClick={onOpen}>
          ログインできない方はこちら
        </ButtonBase>
      </div>
      <ForgetUserAuthDialog
        open={open}
        userName={{ value: userName, errMsg: userNameErr.msg }}
        userMail={{ value: userMail, errMsg: userMailErr.msg }}
        positiveText="認証"
        onChange={{ name: onUserNameChange, mail: onUserMailChange }}
        onBlur={{ name: onUserNameBlur, mail: onUserMailBlur }}
        onClose={onClose}
        onPositiveClick={onAuthForgetUser}
      />
    </>
  );
};
