import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  open: boolean;
  onClose?: () => void;
  width?: React.CSSProperties['width'];
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  height?: React.CSSProperties['height'];
};

interface StylesProps {
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
}

const useStyles = makeStyles((theme) => ({
  paper: (props: StylesProps) => ({
    width: props.width ? props.width : '60%',
    height: props.height ? props.height : '40%',
  }),
}));

export const TakeoutDialog: FC<Props> = ({ className, open, onClose, width, maxWidth, height, children }) => {
  const classes = useStyles({ width, height });

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} classes={{ paper: `${classes.paper} ${className}` }}>
      {children}
    </Dialog>
  );
};
