import { makeStyles } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { useMountEffect } from '../../../hooks/useMountEffect';
import { AuthContext, authOperations } from '../../../modules/general/auth';
import { UIContext } from '../../../modules/general/ui';
import { UiMessage } from '../../general/parts/uiMessage';
import { LoginForm } from '../units/loginForm';
import { UpdateUserAuthForm } from '../units/updateUserAuthForm';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: '#ededed',
    height: '100%',
    position: 'relative',
  },
}));
export const LoginLayout: FC = () => {
  const classes = useStyles();

  const authStore = useContext(AuthContext);
  const uiStore = useContext(UIContext);

  useMountEffect(() => {
    authOperations.csrfCreate(uiStore.dispatch);
  });

  return (
    <div className={classes.wrapper}>
      {!authStore.state.forgetUser ? <LoginForm /> : <UpdateUserAuthForm />}
      <UiMessage />
    </div>
  );
};
