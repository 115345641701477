import { ButtonBase, FormGroup, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { FC, KeyboardEvent, useContext, useState } from 'react';
import { authActions, AuthContext, authOperations } from '../../../modules/general/auth';
import { UIContext } from '../../../modules/general/ui';
import { findNextTabStop } from '../../../utils/findNextTabStop';
import { AdminButton } from '../../general/units/adminButton';
import { AdminInput } from '../../settings/parts/adminInput';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '35%',
    height: '20rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignContent: 'center',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    position: 'absolute',
  },
  formWrapper: {
    width: '80%',
    margin: '5% auto',
  },
  descriptionWrapper: { textAlign: 'center', marginTop: '2rem' },
  description: { fontSize: '1rem', color: '#5b5b5b' },
  button: {
    width: '38%',
    height: '2.8rem',
    margin: 'auto',
  },
  inputWrapper: {
    marginBottom: '0.5rem',
  },
  input: {
    width: '100%',
  },
  errMsgWrapper: {
    marginTop: '0.5rem',
    height: '1rem',
  },
  errMsg: {
    color: 'red',
    fontSize: '0.8rem',
  },
  undoMessageWrapper: {
    textAlign: 'center',
    marginTop: '0.5rem',
  },
  undoMessage: {
    fontSize: '0.5rem',
    color: '#5b5b5b',
  },
}));

export const UpdateUserAuthForm: FC = () => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const authStore = useContext(AuthContext);

  const [id, setId] = useState('');
  const [iDErr, setIdErr] = useState({ flg: true, msg: '' });
  const [idBlurFlg, setIdBlurFlg] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState({ flg: true, msg: '' });
  const [passwordBlurFlg, setPasswordBlurFlg] = useState(false);

  const validateId = (value: string): string => {
    if (value.length < 3) {
      return 'ユーザーIDは3文字以上で入力してください';
    }
    return '';
  };

  const validatePassword = (value: string): string => {
    if (value.length < 8) {
      return 'パスワードは8文字以上で入力してください';
    }
    return '';
  };

  const handleErrorId = (value: string) => {
    const validateResult = validateId(value);
    setIdErr({ flg: !!validateResult, msg: validateResult });
  };

  const handleErrorPassword = (value: string) => {
    const validateResult = validatePassword(value);
    setPasswordErr({ flg: !!validateResult, msg: validateResult });
  };

  const onIdChange = (value: string) => {
    const trimValue = value.trim();

    if (trimValue.length <= 20) {
      setId(trimValue);
      if (idBlurFlg) {
        handleErrorId(trimValue);
      }
    }
  };

  const onPasswordChange = (value: string) => {
    const trimValue = value.trim();

    setPassword(trimValue);
    if (passwordBlurFlg) {
      handleErrorPassword(trimValue);
    }
  };

  const onIdBlur = () => {
    setIdBlurFlg(true);
    handleErrorId(id);
  };

  const onPasswordBlur = () => {
    setPasswordBlurFlg(true);
    handleErrorPassword(password);
  };

  const onUpdateUserAuth = async () => {
    setIdBlurFlg(true);
    setPasswordBlurFlg(true);

    if (iDErr.flg || passwordErr.flg) {
      handleErrorId(id);
      handleErrorPassword(password);
    } else if (authStore.state.forgetUser) {
      await authOperations.updateUser(uiStore.dispatch, authStore.dispatch, {
        pastId: authStore.state.forgetUser.id,
        updateData: { ...authStore.state.forgetUser, id, password },
      });
    }
  };

  const resetForgetUserAuth = () => {
    authStore.dispatch(authActions.resetUserAuth());
  };

  const onEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (id && password) {
        onUpdateUserAuth();
      }
      findNextTabStop(e.target).focus();
    }
  };

  return (
    <Paper elevation={3} className={classes.wrapper}>
      <div className={classes.descriptionWrapper}>
        <Typography className={classes.description}>新しいログイン情報を入力してください</Typography>
      </div>
      <FormGroup className={classes.formWrapper}>
        <div className={classes.inputWrapper}>
          <AdminInput
            className={classes.input}
            placeholder="新しいユーザーID"
            disabled={uiStore.state.loading}
            value={id}
            onChange={onIdChange}
            onBlur={onIdBlur}
            onKeyDown={onEnterDown}
          />
          <div className={classes.errMsgWrapper}>
            <p className={classes.errMsg}>{iDErr.msg}</p>
          </div>
        </div>
        <div className={classes.inputWrapper}>
          <AdminInput
            type="password"
            className={classes.input}
            placeholder="新しいパスワード"
            disabled={uiStore.state.loading}
            value={password}
            onChange={onPasswordChange}
            onBlur={onPasswordBlur}
            onKeyDown={onEnterDown}
          />
          <div className={classes.errMsgWrapper}>
            <p className={classes.errMsg}>{passwordErr.msg}</p>
          </div>
        </div>
        <AdminButton color="positive" text="更新" onClick={onUpdateUserAuth} className={classes.button} />
        <div className={classes.undoMessageWrapper}>
          <ButtonBase className={classes.undoMessage} onClick={resetForgetUserAuth}>
            ログイン画面に戻る
          </ButtonBase>
        </div>
      </FormGroup>
    </Paper>
  );
};
