import { FormGroup, makeStyles, Paper } from '@material-ui/core';
import { navigate } from 'gatsby';
import React, { FC, KeyboardEvent, useContext, useEffect, useState } from 'react';
import { availableFunctions } from '../../../constants/takeout/functions';
import { AuthContext, authOperations } from '../../../modules/general/auth';
import { uiActions, UIContext } from '../../../modules/general/ui';
import { findNextTabStop } from '../../../utils/findNextTabStop';
import { AdminButton } from '../../general/units/adminButton';
import { AdminInput } from '../../settings/parts/adminInput';
import { SelectTransitionDialog } from '../parts/selectTransitionDialog';
import { AuthForgetUserForm } from './authForgetUserForm';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '35%',
    height: '20rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignContent: 'center',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    position: 'absolute',
  },
  formWrapper: {
    width: '80%',
    margin: '5% auto',
    marginTop: 'calc(5% + 1rem)',
  },
  button: {
    minWidth: '38%',
    width: 'max-content',
    height: '2.8rem',
    margin: 'auto',
  },
  inputWrapper: {
    marginBottom: '0.5rem',
  },
  input: {
    width: '100%',
  },
  errMsgWrapper: {
    marginTop: '0.5rem',
    height: '1rem',
  },
  errMsg: {
    color: 'red',
    fontSize: '0.8rem',
  },
}));

export const LoginForm: FC = () => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const authStore = useContext(AuthContext);

  const [id, setId] = useState('');
  const [iDErr, setIdErr] = useState({ flg: true, msg: '' });
  const [idBlurFlg, setIdBlurFlg] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState({ flg: true, msg: '' });
  const [passwordBlurFlg, setPasswordBlurFlg] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const validateId = (value: string): string => {
    if (value.length < 3) {
      return 'ユーザーIDは3文字以上で入力してください';
    }
    return '';
  };

  const validatePassword = (value: string): string => {
    if (value.length < 8) {
      return 'パスワードは8文字以上で入力してください';
    }
    return '';
  };

  const handleErrorId = (value: string) => {
    const validateResult = validateId(value);
    setIdErr({ flg: !!validateResult, msg: validateResult });
  };

  const handleErrorPassword = (value: string) => {
    const validateResult = validatePassword(value);
    setPasswordErr({ flg: !!validateResult, msg: validateResult });
  };

  const onIdChange = (value: string) => {
    const trimValue = value.trim();

    if (trimValue.length <= 20) {
      setId(trimValue);
      if (idBlurFlg) {
        handleErrorId(trimValue);
      }
    }
  };

  const onPasswordChange = (value: string) => {
    const trimValue = value.trim();

    setPassword(trimValue);
    if (passwordBlurFlg) {
      handleErrorPassword(trimValue);
    }
  };

  const onIdBlur = () => {
    setIdBlurFlg(true);
    handleErrorId(id);
  };

  const onPasswordBlur = () => {
    setPasswordBlurFlg(true);
    handleErrorPassword(password);
  };

  const navigateTransiton = () => {
    if (availableFunctions.length === 0) {
      uiStore.dispatch(uiActions.fetchFailed('使用できる機能がありません。管理者にお問い合わせください。'));
      return;
    }
    if (availableFunctions.length === 1) {
      navigate(availableFunctions[0].path);
      return;
    }
    // 機能が2つ以上の場合はダイアログを表示
    setIsDialogOpen(true);
  };

  const onLoginClick = async () => {
    setIdBlurFlg(true);
    setPasswordBlurFlg(true);

    if (iDErr.flg || passwordErr.flg) {
      handleErrorId(id);
      handleErrorPassword(password);
    } else {
      await authOperations.login(uiStore.dispatch, authStore.dispatch, id, password);
    }
  };

  // ログイン認証が完了した後、遷移先決定処理
  useEffect(() => {
    if (authStore.state.user) {
      navigateTransiton();
    }
  }, [authStore.state.user]);

  const onEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (id && password) {
        onLoginClick();
      }
      findNextTabStop(e.target).focus();
    }
  };

  return (
    <>
      <Paper elevation={3} className={classes.wrapper}>
        <FormGroup className={classes.formWrapper}>
          <div className={classes.inputWrapper}>
            <AdminInput
              className={classes.input}
              placeholder="ユーザーID"
              disabled={uiStore.state.loading}
              value={id}
              onChange={onIdChange}
              onBlur={onIdBlur}
              onKeyDown={onEnterDown}
            />
            <div className={classes.errMsgWrapper}>
              <p className={classes.errMsg}>{iDErr.msg}</p>
            </div>
          </div>
          <div className={classes.inputWrapper}>
            <AdminInput
              type="password"
              className={classes.input}
              placeholder="パスワード"
              disabled={uiStore.state.loading}
              value={password}
              onChange={onPasswordChange}
              onBlur={onPasswordBlur}
              onKeyDown={onEnterDown}
            />
            <div className={classes.errMsgWrapper}>
              <p className={classes.errMsg}>{passwordErr.msg}</p>
            </div>
          </div>
          <AdminButton color="positive" text="ログイン" onClick={onLoginClick} className={classes.button} />
          <AuthForgetUserForm />
        </FormGroup>
      </Paper>
      <SelectTransitionDialog isOpen={isDialogOpen} />
    </>
  );
};
